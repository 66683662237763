import { Component } from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
/** Error component*/
export class ErrorComponent {
    /** Error ctor */
    constructor() {

    }
}