import { Component, OnInit , ViewChild , ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common'; 
import { Inject }  from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  @ViewChild('iframe' , {static: false}) iframe: ElementRef
  urlSafe: SafeResourceUrl;
  logoutlink;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private http: HttpClient , @Inject(DOCUMENT) document) {
      
     }



  /*async ngAfterViewInit() {
    await this.authenticationService.GetIamLogoutLink()
    .then(result => {
      this.logoutlink = result.LogoutLink;
   }).catch(err => {
     
   });
    var redirectUri = this.cookieService.get('logoutRedirectUri');
    debugger;
    
    if(redirectUri != "" && redirectUri != null){
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.iam.sa/samlsso?slo=true");
      this.iframe.nativeElement.setAttribute('src',this.urlSafe);
      //this.cookieService.delete('logoutRedirectUri');
      setTimeout(()=>{
        window.location.href = "/SSOAbsher/logout";
      },2000)
    
  
  }
}*/

  ngOnInit() {
    //console.log('in main');

    


     
      
    }

    


    
  }

  
