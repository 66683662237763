import { Component, OnInit } from '@angular/core';
import {NgbModal , NgbActiveModal , NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class InfoModalComponent implements OnInit {
  success:boolean = true;
  redirectState:string = "login";
  customUrl;
  loading;
  message;
  constructor(
    private router: Router , public activeModal: NgbActiveModal , config: NgbModalConfig
  ) {
   
    config.backdrop = 'static';
    config.keyboard = false;

   }

   redirect(){
     if(this.success == false){
       this.activeModal.close();
       return;
     }
     if(this.redirectState == 'login'){
      this.router.navigate(['/login']);
     }
     else if(this.redirectState == 'userprofile'){
       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
         this.router.navigate(['userprofile']));
     }
     else if(this.redirectState == 'custom'){
      window.location.href = this.customUrl;
     }
     this.activeModal.dismiss();
    
   }


  ngOnInit() {
  }

}
