import { Component, OnInit ,Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MustMatch } from '../../../_helpers/must-match';
import { locale } from './locale';
import {  NgbModal , NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import{CancelPopUpComponent} from '../cancel-pop-up/cancel-pop-up.component';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class EditPasswordComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  @Input()
  nin: string;
  submitted = false;
  error = '';
  locale = locale;
  @Output() editPasswordResult = new EventEmitter<any>();;
  @Output() editPasswordError = new EventEmitter<any>();
  cancelModal : CancelPopUpComponent;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal , config: NgbModalConfig
  ) { 
    config.backdrop = 'static';
    config.keyboard = false;
  }

  async ngOnInit() {
    this.registerForm = this.formBuilder.group({
      oldPass: ['', [Validators.required, Validators.minLength(6), Validators.pattern(('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9$@$!%*?&#^-_. +]+$'))]],
      newPass: ['', Validators.required]
    },
      {
        validator: MustMatch('oldPass', 'newPass')

      }
    );

  }

  async onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {

      return;
    }
    this.loading = true;
    await this.authenticationService.resetPassword(this.nin, this.f.newPass.value).then(data => {
      this.editPasswordResult.emit(data);
    }).catch(err => {
      this.editPasswordError.emit(err);
    })
    this.loading = false;

  }
  cancel() {
    
    const modalRef = this.modalService.open(CancelPopUpComponent);
    this.cancelModal = modalRef.componentInstance;
    this.cancelModal.message = 'لن يتم حفظ كلمة المرور الجديدة. هل تريد الاستمرار؟ '
    this.cancelModal.redirectEvent.subscribe(data=>{
      if(data){
        
        this.router.navigate(['/login']);
        modalRef.close();
      }
    });
  }
  
  get f() { return this.registerForm.controls; }



}
