export var locale = {
  RegistrationTitle: "إنشاء حساب جديد",
  NationalId: "رقم الهوية / الاقامة",
  RequiredFieldMsg: "هذا الحقل مطلوب",
  IncorrectValue: "القيمة المدخلة غير صحيحة",
  RegisterButton: "إنشاء حساب جديد",
  LoginWithAbsher: "الدخول مع النفاذ الوطني الموحد",
  Password: "كلمة المرور",
  ForgetPassButton: "نسيت كلمة المرور ؟",
  FAQ:"الأسئلة الشائعة"


}

export var responseEnum = {
  Success: { code: 1, message: "" },
  WrongCredentials: { code: 2, message: "تسجيل دخول خاطئ، الرجاء التحقق من رقم الهوية و كلمة المرور" },
  MaxAttempsReached: { code: 3, message: "تسجيل دخول خاطئ، الرجاء التحقق من رقم الهوية و كلمة المرور" },
  PasswordChangeNeeded: { code: 4, message: "" },
  UpdateProfileRequired: { code: 5, message: "" }
}



export var errorMessage = ' عذرا, حدث خطأ فني أثناء تنفيذ العملية. يرجى المحاولة في وقت لاحق';
