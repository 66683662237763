import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DNTCaptchaApiResponse, DNTCaptchaApiValue } from "../_components/dnt-captcha/dnt-captcha-api-response";
import CryptoJs from 'crypto-js';

@Injectable({
    providedIn: 'root'
  })
export class CaptchaService {
   private apiKey: string;
   private apiUrlGetCapcha = "/UserVerfication/GenerateJasonImagePrivateKey";
   private apiUrlValidCapcha = "/UserVerfication/IsValidJasonImage";

    constructor(private http: HttpClient) {
        this.getClient();
    }
    private DecryptData(key, ciphertextB64) {
        var key = CryptoJs.enc.Utf8.parse(key);
        var iv = CryptoJs.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
        var decrypted = CryptoJs.AES.decrypt(ciphertextB64, key, { iv: iv });
        return decrypted.toString(CryptoJs.enc.Utf8);
    }
    EncryptClient(value) {

        var keyBytes = CryptoJs.PBKDF2(this.apiKey, this.apiKey, { keySize: 48 / 4, iterations: 1000 });
        var key = new CryptoJs.lib.WordArray.init(keyBytes.words, 32);
        var iv = new CryptoJs.lib.WordArray.init(keyBytes.words.splice(32 / 4), 16);
        var data = CryptoJs.enc.Utf16LE.parse(value);
        var encrypted = CryptoJs.AES.encrypt(data, key, { iv: iv });
        return encrypted.toString();
    }
    async getClient() {
        debugger;
        const jsonFile = "assets/Configuration/ClientApiKey.json";
        await this.http.get<any>(jsonFile).toPromise().then(res => {
            this.apiKey = res.ApiKey;
        }).catch(err => {
        })

    }
    private handleError(error: HttpErrorResponse): Observable<any> {
        console.error("getCaptchaInfo error: ", error);

        return Observable.throw(error.statusText);
    }
    async IsvalidCapch(inputText,ServerValue) {
        debugger;
        let request = {
            ClientValue: this.EncryptClient(inputText),
            ServerValue: ServerValue,
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return await this.http.post<DNTCaptchaApiValue>(this.apiUrlValidCapcha, JSON.stringify(request), { headers: headers }
        ).pipe(
            map(
                data => {
                    return new DNTCaptchaApiValue(
                        data["Value"],
                        data["Text"]
                    );
                }
            )
        ).catch(this.handleError).toPromise();
        /*.subscribe(
          data => {
          this.apiValue=data;
         }
        );*/

    }
    GetCaptcha() {
        debugger;
        return this.http.get<DNTCaptchaApiResponse>(this.apiUrlGetCapcha)
            .pipe(
                map(
                    data => {
                        return new DNTCaptchaApiResponse(
                            'data:image/png;base64,' + data['Image'],
                            data['Key'],
                            data['Value'],
                            data['ClientValue'],
                            data['ServerValue'],
                            data['Text'],
                        );
                    }
                )
            ).catch(this.handleError).toPromise();
            /*.subscribe(
                data => {
                    this.apiResponse = data;
                }
            )*/
            ;
            
    }
}