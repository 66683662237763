import { Injectable } from '@angular/core';
import Encrypt from '../_helpers/crypto';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '../_models/Client';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;
  private clientSubject: BehaviorSubject<number>;
  public currentClient: Observable<number>;

  constructor(
    private http: HttpClient,
  ) {
    let user;
    this.userSubject = new BehaviorSubject<any>(user);
    this.user = this.userSubject.asObservable();
    let client : Client;
    this.clientSubject = new BehaviorSubject<number>(1);
    this.currentClient = this.clientSubject.asObservable();
   }

   public get userValue(): any {
    return this.userSubject.value;
  }

  public get clientValue(): number{
    return this.clientSubject.value;
  }

  async setClient(client) {
    debugger;
    const jsonFile = "assets/Configuration/ClientConfig.json";
    await this.http.get<any>(jsonFile).toPromise().then(res => { 
      let EServicesClientId = res.EServicesClient;
      let TamekeenClientId = res.TamekeenClientId;
      var clientType = 1
      if (TamekeenClientId.includes(client))
        clientType = 2;
      else if (client == EServicesClientId)
        clientType = 1;
      this.clientSubject.next(clientType);
    }).catch(err => {
    })


    /*var clientType = 1
    if (client == 2)
      clientType = 2;
    else if (client == 1)
      clientType = 1;
    this.clientSubject.next(clientType);*/
  }

  setUser(user) {
    this.userSubject.next(user);
  }

  isAuthenticated(){
    return this.http.get<any>('Userprofile/IsAuthenticated/').toPromise();
  }
  

   getUserProfileDetails(){
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
     
    };
    return this.http.get<any>('Userprofile/GetUserProfileDetails/',httpOptions )
              .pipe(map(data => {
               
                this.userSubject.next(data);
                
            }))
   }

   getBankName(iban:string){

    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { 'bankAccount':iban}
    };
    return  this.http.get<any>('Userprofile/GetAccountBankName/',httpOptions ).toPromise();
              
    
  }
  ValidatePhoneNumber(phoneNumber: string) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { 'phoneNumber': phoneNumber }
    };
    return this.http.get<any>('Userprofile/ValidatePhoneNumber/', httpOptions).toPromise();

  }

   changePassword(newPassword , oldPassword){
    var credentails = oldPassword + String.fromCharCode(31) + newPassword;
    let cipher = Encrypt(credentails);
  
     let request = {
       cred: cipher.toString()
     }
    return this.http.post("/Userprofile/ChangePassword/", request).toPromise();
   }


   ta3eenPassword(newPassword){
     let cipher = Encrypt(newPassword);
     let request = {
       cred: cipher.toString()
     }
     return this.http.post("/Userprofile/AddPassword/", request).toPromise();
   }

   updateUserProfile(userProfile){
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      
    };
    return this.http.post<any>('Userprofile/UpdateUserProfile/',userProfile , httpOptions).toPromise();
   }

   updateMobile( mobile){
 
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: {'mobile':mobile,}
    };
   return this.http.post<any>('Userprofile/UpdateMobile/',null , httpOptions).toPromise();
   }
}
