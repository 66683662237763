import { Component, OnInit , ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import {DateDropdownComponent} from '../_shared-components/date-dropdown/date-dropdown.component';
import {MustMatch} from '../../_helpers/must-match'
import { CaptchaComponent } from '../_shared-components/captcha/captcha.component';
import { CaptchaQuestion} from '../../_models/CaptchaModel';
import {RegistrationModel} from '../../_models/RegistrationModel';
import { responseEnum, errorMessage, locale } from './locale';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../_shared-components/info-modal/info-modal.component';
import { NewloginComponent } from '../newlogin/newlogin.component';
import { DntCaptchaComponent } from '../dnt-captcha/dnt-captcha.component';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild(DateDropdownComponent , { static: false }) dateComponent: DateDropdownComponent;
  @ViewChild(DntCaptchaComponent , {static: true}) dntCaptchaComponent : DntCaptchaComponent;
  ValidateNewLogin = false; 
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  infoModal: InfoModalComponent;
  error = '';
  birthDate : string;
  captchaQuestion:CaptchaQuestion;
  responseCode;
  locale = locale;
  MessageAlert='';
  ValidateLogin=false;
  ValidateDtn;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal, config: NgbModalConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  async ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nationalID: ['' , [Validators.required,Validators.pattern('[1|2][0-9]{9}')]],
      phoneNumber: ['', [Validators.required , Validators.pattern('05[0-9]{8}')]] ,
      confirmphoneNumber: ['', [Validators.required ]],
      approvalInfo:  ['', [Validators.required , Validators.requiredTrue]]
      
    }
    , {
      validator: MustMatch('phoneNumber', 'confirmphoneNumber')
    });
  
  }
  ngAfterViewInit() {
    this.registerForm.addControl('dateForm', this.dateComponent.dateForm);
    this.dateComponent.dateForm.setParent(this.registerForm);
 
 
  }

  Refreshed(myEvent : Event)
  { 
    if(myEvent!=null)
    {
       debugger;
       if (Boolean(myEvent) == true) 
       {
          this.MessageAlert="";
          this.dntCaptchaComponent.isShown='Required';
       }
    }
  }
  getStatus(myEvent : Event)
  { 
    if(myEvent!=null)
    {
          this.ValidateNewLogin=  Boolean(myEvent); 
       if (this.ValidateNewLogin == false ) 
       {
          this.MessageAlert="القيمة المدخلة خطأ الرجاء إعادة المحاولة";
       } 
       else  if (this.ValidateNewLogin == true) 
       {
          this.MessageAlert=("القيمة المدخلة صحيحة");
       }
    }
  }
  /*getStatusDnt(myEvent : Event)
  { 
    this.ValidateNewLogin=false;
    this.MessageAlert='';
    this.ValidateDtn=null;
    if(myEvent!=null)
    {
      debugger;
              this.ValidateDtn=  myEvent;
              if (this.ValidateDtn == "Wright" ) 
              {
                   this.MessageAlert=("القيمة المدخلة صحيحة");
                  this.ValidateDtn=true;
                  this.ValidateNewLogin=true;
              } 
              else  if (this.ValidateDtn == "Wrong") 
              { 
                  this.MessageAlert="القيمة المدخلة خطأ الرجاء إعادة المحاولة";
                  this.ValidateDtn=false;
                  this.ValidateNewLogin=false;
              }
            
     }
  }*/
   async onSubmit() {
          this.submitted = true;
    //this.captchaComponent.validateCaptcha();
    
    debugger;
      this.dntCaptchaComponent.onCapchaFinshed().then( 
        a=> { 
          this.MessageAlert=a.Text ,this.ValidateLogin=a.valid
            if(this.registerForm.valid && this.ValidateLogin)
          {
                  this.register(true);
                  this.loading = true;
          }
        } 
        );

      

    
 }

 async register(e){
   if(e){
   

    if (this.registerForm.invalid) {
      this.loading = false;
      return;
    }

    let regModel:RegistrationModel = {
      nationalId: this.f.nationalID.value,
      birthDate: this.birthDate,
      mobile: this.f.phoneNumber.value
     }
     await this.authenticationService.register(regModel).then(result => {
      this.responseCode = result;
      this.setResponseAction();
  
    }).catch(err => {
      this.error = errorMessage;
    });
      this.loading = false;
   }
   else{
    this.loading = false;
  
   }

 }

  setResponseAction() {
  if(this.responseCode == responseEnum.InDB.code){
    this.error = responseEnum.InDB.message;
  }
  else if(this.responseCode == responseEnum.MobileFound.code){
    this.error = responseEnum.MobileFound.message;
  }
  else if (this.responseCode == responseEnum.Success.code) {

    const modalRef = this.modalService.open(InfoModalComponent);
    this.infoModal = modalRef.componentInstance;
    this.infoModal.success = true;
    this.infoModal.message = responseEnum.Success.message;
    
  }
 
  else if (this.responseCode == responseEnum.NotFound.code){
    this.error = responseEnum.NotFound.message;
  }
  else if(this.responseCode == responseEnum.WrongNationalId.code){
    this.error = responseEnum.WrongNationalId.message;
  }
  else{
    this.error = errorMessage;
  }
 }

 onCaptchaError(e){
  this.error = errorMessage;
 }

 setDate(e){
   this.birthDate = e;
 }
  goToFAQ() {
    this.router.navigate(['/FAQ']);
  }
 goBack(){
  this.router.navigate(['/login']);
 }

  get f() { return this.registerForm.controls; }

}
