import { Injectable } from '@angular/core';
import Encrypt from '../_helpers/crypto';
import { HttpClient } from '@angular/common/http';
import {CaptchaModel } from '../_models/CaptchaModel';
import{RegistrationModel} from '../_models/RegistrationModel';
import { ForgetPasswordModel } from '../_models/ForgetPasswordModel';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) {
   
  }

  GetIamStatus() {
    const jsonFile = "assets/Configuration/IAMConfig.json";
    return this.http.get<any>(jsonFile).toPromise();
  }

  GetClientConfig() {
    const jsonFile = "assets/Configuration/ClientConfig.json";
    return this.http.get<any>(jsonFile).toPromise();
  }

  GetIamLogoutLink() {
    const jsonFile = "assets/Configuration/IAMlink.json";
    return this.http.get<any>(jsonFile).toPromise();
  }

  login(username: string, password: string) {
  
    let credentails: string = username + String.fromCharCode(31) + password + String.fromCharCode(31);
    let cipher = Encrypt(credentails);
    let request = {
      cred: cipher.toString()

    }
    return this.http.post<any>("/Auth/Login/", request).toPromise();


  }


  initiateMobileVerification(nationalId:string , mobile:string){
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { 'NationalId': nationalId , 'mobile': mobile}
    };
    return this.http.get<any>('/UserVerfication/InitiateMobileVerification', httpOptions).toPromise();
  }

  register(regModel:RegistrationModel){
    return this.http.post<any>("/Auth/Register/", regModel).toPromise();
  }

  validateCaptcha(captchaModel : CaptchaModel){
    return this.http.post("/UserVerfication/IsValidCaptcha/", captchaModel).toPromise();
  }

  generateCaptcha(){
    return this.http.post("/UserVerfication/GenerateCaptcha/", null).toPromise();
  }

  postAbsherSignIn() {
    return this.http.get("/SSOAbsher/PostSuccessfulLogin/").toPromise();
  }

  postAbsherSignOut() {
    return this.http.get("/SSOAbsher/logout/").toPromise();
  }


  forgetPassword(forgetPasswordModel:ForgetPasswordModel){
    return this.http.post("Auth/ForgetPassword",forgetPasswordModel).toPromise();
  }

  verifyPassCode(forgetPasswordModel:ForgetPasswordModel){
    let HTTPOptions:Object = {

    
      responseType: 'text'
   }
    return this.http.post<string>("Auth/VerifyUserPassCode",forgetPasswordModel ,HTTPOptions).toPromise();
  }

  resetPassword(username: string, password: string){
    let credentails: string = username + String.fromCharCode(31) + password + String.fromCharCode(31);
    let cipher = Encrypt(credentails);
    let request = {
      cred: cipher.toString()

    }

    return this.http.post("/Auth/ResetPassword/", request).toPromise();
  }

}
