import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './_components/login/login.component';
import { ErrorComponent } from './_components/error/error.component';
import { FaqComponent } from './_components/faq/faq.component';
import { AbsherLoginComponent } from './_components/absher-login/absher-login.component';
import { AbsherLogoutComponent } from './_components/absher-logout/absher-logout.component';
import { RegisterComponent } from './_components/register/register.component';
import { ForgetPasswordComponent } from './_components/forget-password/forget-password.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SharedModule } from './_components/_shared-components/shared.module'
import { CookieService } from 'ngx-cookie-service';
import { MainComponent } from './_components/main/main.component';
import { NgxCaptchaModule, NgxCaptchaService} from '@binssoft/ngx-captcha';
import { NewloginComponent } from './_components/newlogin/newlogin.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ErrorComponent,
    AbsherLoginComponent,
    AbsherLogoutComponent,
    NewloginComponent,
    FaqComponent,
    MainComponent  
    
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxCaptchaModule,
     NgHttpLoaderModule.forRoot(),
  ],
  exports:[
    
  ],
  providers: [CookieService,NgxCaptchaService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
