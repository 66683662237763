

export var responseEnum = {
  InDB: { code: 4, message: "رقم الهوية مسجل من قبل، يرجى تسجيل الدخول أو الضغط على نسيت كلمة المرور" },
  MobileFound: { code: 6, message: "رقم الجوال مسجل  من قبل" },
  UserExistInTempDB: { code: -6, message: "لقد تم حفظ البيانات من قبل وسيتم إرسال كلمة مرور عشوائية إلى رقم الجوال المسجل" },
  WrongNationalId: { code: -5, message: "رقم الهوية غير صحيح" },
  Success: { code: 5, message: "تم تسجيل الحساب بنجاح" },
  NotFound: { code: 0, message: "عذرا، لا يمكنك الاستمرار، البيانات المدخلة لا تتطابق مع سجلات الأحوال المدنية، يرجى مراجعتهم للتصحيح" },

}

export var errorMessage = ' عذرا, حدث خطأ فني أثناء تنفيذ العملية. يرجى المحاولة في وقت لاحق';

export var locale = {
  InDB: "رقم الهوية مسجل من قبل، يرجى تسجيل الدخول أو الضغط على نسيت كلمة المرور",
  RegistrationTitle: "إنشاء حساب جديد",
  NationalId: "رقم الهوية / الاقامة",
  RequiredFieldMsg: "هذا الحقل مطلوب",
  IncorrectValue: "القيمة المدخلة غير صحيحة",
  DateBirth:"تاريخ الميلاد",
  Mobile: "رقم الجوال",
  ConfirmMobile: "تأكيد رقم الجوال",
  MissMatchPhoneNumber:'عذرا، القيمة غير مطابقة لقيمة "رقم الجوال"',
  FAQ: "الأسئلة الشائعة"

}
