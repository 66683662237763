import { Component, OnInit , ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import {DateDropdownComponent} from '../_shared-components/date-dropdown/date-dropdown.component';
import { CaptchaComponent } from '../_shared-components/captcha/captcha.component';
import { CaptchaQuestion} from '../../_models/CaptchaModel';
import {  NgbModal , NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import{OtpComponent} from '../../_components/_shared-components/otp/otp.component';
import {ForgetPasswordModel} from '../../_models/ForgetPasswordModel'
import {responseEnum , errorMessage , otpResponse, locale} from './locale'
import{EditPasswordComponent} from '../_shared-components/edit-password/edit-password.component';
import {InfoModalComponent} from '../_shared-components/info-modal/info-modal.component';
import { CookieService } from 'ngx-cookie-service';
import Encrypt from '../../_helpers/crypto';
import { DntCaptchaComponent } from '../dnt-captcha/dnt-captcha.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  @ViewChild(DateDropdownComponent , { static: false }) dateComponent: DateDropdownComponent;
  @ViewChild(EditPasswordComponent , {static: false}) editPassword : EditPasswordComponent;
  @ViewChild(DntCaptchaComponent , {static: true}) dntCaptchaComponent : DntCaptchaComponent;
  otpView : OtpComponent;
  infoModal : InfoModalComponent;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  birthDate : string;
  captchaQuestion:CaptchaQuestion;
  captchaErrorCount : number = 0;
  isCaptcha: boolean = false;
  responseCode;
  errMessage;
  locale = locale;
  newPassView = false;
  MessageAlert ='';
  ValidateLogin;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService ,
    private modalService: NgbModal , config: NgbModalConfig,
    private cookieService: CookieService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
   }


  async ngOnInit() {
    this.cookieService.delete('id.dfg');
    this.cookieService.delete('id.nv');
    this.registerForm = this.formBuilder.group({
      nationalID: ['' , [Validators.required,Validators.pattern('[1|2][0-9]{9}')]],
      phoneNumber: ['', [Validators.required , Validators.pattern('05[0-9]{8}')]] 
     ,
      
    }
    );

  }
   ngAfterViewInit() {
    this.registerForm.addControl('dateForm', this.dateComponent.dateForm);
    this.dateComponent.dateForm.setParent(this.registerForm);
   
  
  }
  Refreshed(myEvent : Event)
  { 
    if(myEvent!=null)
    {
       debugger;
       if (Boolean(myEvent) == true) 
       {
          this.MessageAlert="";
          this.dntCaptchaComponent.isShown='Required';
       }
    }
  }
  onSubmit() {
    debugger;
        this.editPassword.nin = this.f.nationalID.value;
        //this.MessageAlert=this.dntCaptchaComponent.onCapchaFinshed();
        
        this.submitted = true;
        this.dntCaptchaComponent.onCapchaFinshed().then(a=> { 
          this.MessageAlert=a.Text , this.ValidateLogin=Boolean(a.valid)
          if (this.registerForm.invalid || !this.ValidateLogin) 
          { 
              return;
          }
          
          this.loading = true;
          if(!this.isCaptcha){
              this.sendForgetPasswordRequest();
          }
          else{
            //this.addCaptchaToForm();
            if (this.registerForm.invalid) {
              
              return;
            }
          // this.captchaComponent.validateCaptcha();
          }
        });
       

 }

 async openCaptchaOtp(){
    const modalRef = this.modalService.open(OtpComponent);
    this.otpView = modalRef.componentInstance;
    this.otpView.isCaptchaNeeded = true;
    this.otpView.isPassiveMode = true;
    this.error = '';
    this.otpView.nationalId = this.f.nationalID.value;
    this.otpView.mobile = this.f.phoneNumber.value;
    this.otpView.title = 'التحقق من رقم الجوال لإستعادة كلمة المرور'
    this.otpView.otpResult.subscribe(

    data => {
      
      let forgetPasswordModel : ForgetPasswordModel = {
        NationalId : this.f.nationalID.value,
        Mobile: this.f.phoneNumber.value,
        BirthDate: this.birthDate,
        Code: data.toString()
      }

      

      this.authenticationService.verifyPassCode(forgetPasswordModel).then(result => {
        debugger;
        this.responseCode = result;
        //this.responseCode = true
        if(!(typeof result === 'string')){
          this.otpView.error = errorMessage;
          return
        }
        if(result[2] == '-'){
          this.responseCode = -5;
        }
        else{
          this.responseCode = parseInt(result[3]);
        }
       if(this.responseCode === otpResponse.SMSSent.code || this.responseCode === otpResponse.OTPVerified.code){
          //modalRef.close();
          this.newPassView = true;
          this.setSecureCookie(this.f.nationalID.value);
          //modalRef.close();
          //modalRef.dismiss();
          this.modalService.dismissAll();
        }
        else if(this.responseCode === otpResponse.OTPVerificationFailed.code){
          
          this.otpView.error = otpResponse.OTPVerificationFailed.message;
        }
        else if (this.responseCode === otpResponse.OTPExpired.code){
         
          this.otpView.error = otpResponse.OTPExpired.message;
        }
        else if (this.responseCode === otpResponse.Error.code){
        
          this.otpView.error = errorMessage;
        }
      }).catch(err => {
        this.error = errorMessage;
      });
      ///OPEN SUCCESS POP UP HERE//////
       
    },
    error => {
    });

    this.otpView.otpError.subscribe(
      data => {
    
         this.loading = false;
      },
      error => {
      });

 }



 async sendForgetPasswordRequest(){
  let forgetPasswordModel : ForgetPasswordModel = {
    NationalId : this.f.nationalID.value,
    Mobile: this.f.phoneNumber.value,
    BirthDate: this.birthDate,
    Code:null
  }

  await this.authenticationService.forgetPassword(forgetPasswordModel).then(result => {
    this.responseCode = result;
    
    if(this.responseCode == responseEnum.UserNotAvail.code){
      this.error = responseEnum.UserNotAvail.message;
   
    }
    else if(this.responseCode == responseEnum.UserNotAvail_ShowCaptcha.code){
      this.error = responseEnum.UserNotAvail.message;
     
    }
    else if(this.responseCode == responseEnum.Success.code){
      this.openCaptchaOtp();
    }
    else{
      this.error = responseEnum.UserNotAvail.message;
    }
    this.submitted = false;

  }).catch(err => {
    this.error = errorMessage;
  });
    this.loading = false;
 }

 onEditPasswordError(e){
  this.editPassword.error = errorMessage;

}


onEditPasswordResult(e){
  const modalRef = this.modalService.open(InfoModalComponent);
  this.infoModal = modalRef.componentInstance;
  this.infoModal.success = true;
  this.infoModal.message = responseEnum.Success.message;
  this.cookieService.delete('id.dfg');
  this.cookieService.delete('id.nv');
}
 setDate(e){
  this.birthDate= e;
}

goBack(){
 this.router.navigate(['/login']);
}

setSecureCookie(nin){
  this.cookieService.delete('id.dfg');
  var timeStamp = Date.now();
  let credentails: string = nin + String.fromCharCode(31) + timeStamp + String.fromCharCode(31);
  let cipher = Encrypt(credentails);
  this.cookieService.set('id.dfg', cipher);
}

register(e){
  
}


 get f() { return this.registerForm.controls; }

}
