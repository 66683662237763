import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import {NgxCaptchaService} from  '@binssoft/ngx-captcha'
@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrls: ['./newlogin.component.css']
})
export  class  NewloginComponent  implements  OnInit {
  @Output() captchaStatus = new EventEmitter();
   
   captchaConfig:any = {
     length:6,
     cssClass:'null',
     back: {
       stroke:"#2F9688",
       solid:"#f2efd2"
     } ,
     font:{
      color:"#000000",
      size:"35px"
     }
     
     
     
   };
  

   constructor(  private captchaService:NgxCaptchaService
    ){
      

     }
   ngOnInit() {
        this.captchaService.captchStatus.subscribe((status)=>{
          this.captchaStatus.emit(status);
    
        });
   }
  }