import { Component, OnInit  , ViewChild } from '@angular/core';
import {CaptchaComponent} from '../_shared-components/captcha/captcha.component'
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { responseEnum, errorMessage, locale} from './locale'
import {  NgbModal , NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { OtpComponent } from '../../_components/_shared-components/otp/otp.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  mobileMode: boolean = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  captchaErrorCount = 0;
  locale = locale;
  isCaptchaNeeded : boolean = false;
  @ViewChild(CaptchaComponent , {static: false}) captchaComponent : CaptchaComponent; 
  otpView : OtpComponent;
  modalOTP;
  AbsherEnabled;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal, config: NgbModalConfig,
    private cookieService: CookieService
  ) { 
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParamMap.get('ReturnUrl');
    if (this.returnUrl == null) {
      this.returnUrl = sessionStorage.getItem('returnUrl');
    }
    sessionStorage.setItem('returnUrl', this.returnUrl);

    
    let UriSerach = decodeURIComponent(window.location.search).split("&");
    let ClientString = UriSerach.find(data => data.includes("client_id"));
    if (ClientString) {
      let firstIndex = ClientString.indexOf("client_id=") + 10;
      let ClientId = ClientString.substring(firstIndex);
      if (ClientId != null && ClientId != "") {
        sessionStorage.setItem('Client_Id', ClientId);
        this.cookieService.set('Client_Id', ClientId);
        this.authenticationService.GetClientConfig()
          .then(result => {
            if(result && result.MobileIds){
              if(result.MobileIds.indexOf(ClientId) > -1){
                this.mobileMode = true;
                window.location.href = '/Saml2/SignIn';
              }
            }
            
          }).catch(err => {
        
      });
      }
    }

    this.loginForm = this.formBuilder.group({
      nationalID: ['', [Validators.required ,  Validators.pattern('[1|2][0-9]{9}')]],
      password: ['', Validators.required]
    });

    this.authenticationService.GetIamStatus()
       .then(result => {
         this.AbsherEnabled = result;
      }).catch(err => {
        
      });

  }


  get f() { return this.loginForm.controls; }

  async onLoginClick() {
    this.submitted = true;
      if (this.loginForm.invalid) {
        if(this.isCaptchaNeeded){
          this.captchaComponent.submitted = true;;
        }
            return;
      }
    
    if(this.isCaptchaNeeded){
      if(this.captchaComponent.captchaForm.invalid){
        return;
      }
      this.loading = true;
      this.captchaComponent.validateCaptcha();
    }
    else{
      this.loading = true;
      await this.sendLoginRequest();
    }
  
        
    }

    addCaptchaToForm(){
      this.loading = false;
      this.isCaptchaNeeded = true;
      this.loginForm.addControl('captchaForm', this.captchaComponent.captchaForm);
      this.captchaComponent.captchaForm.setParent(this.loginForm);
      this.captchaComponent.submitted = false;
    }

    async sendLoginRequest(){
      await this.authenticationService.login(this.f.nationalID.value, this.f.password.value)//output: 'First Example'
      .then(result => {
        if(result.loginStatus == responseEnum.Success.code){
          if(result.isTwoFactorAuthEnabled){
            this.modalOTP = this.modalService.open(OtpComponent);
            this.loading = true;
            this.otpView = this.modalOTP.componentInstance;
            this.otpView.isPassiveMode = false;
            this.otpView.isCaptchaNeeded = false;
            this.otpView.nationalId = this.f.nationalID.value;
            //this.otpView.mobile = this.f.number.value;
            this.otpView.otpCancel.subscribe( res =>{
              if(res){
                this.loading = false;
              }
            });
            this.otpView.otpResult.subscribe(
              data => {
                if (data) {
                  this.modalOTP.close();
                this.redirect();
                
                }
                 
              },
              error => {
              });
          
              this.otpView.otpError.subscribe(err=>{
                this.loading = false;
              });

          }
          else{
            this.redirect();
          }
         
        }
        else if(result.loginStatus == responseEnum.WrongCredentials.code){
       
          this.error = responseEnum.WrongCredentials.message;
          this.captchaErrorCount++;
          if(this.captchaErrorCount >= 5){
            this.isCaptchaNeeded = true;
            if (this.isCaptchaNeeded) {
              this.captchaComponent.generateCaptcha();
            }
            else {
              this.addCaptchaToForm();
            }
          }
          
        }
        else if(result.loginStatus == responseEnum.MaxAttempsReached.code){
          this.error = responseEnum.WrongCredentials.message
          if (this.isCaptchaNeeded) {
            this.captchaComponent.generateCaptcha();
          }
          else {
            this.addCaptchaToForm();
          }
          this.isCaptchaNeeded = true;
          
        }
        else if (result.loginStatus == responseEnum.PasswordChangeNeeded.code) {
          sessionStorage.setItem('isUpdateNeeded', '1');
          this.router.navigate(['/userprofile/editpass']);
        }
        else if (result.loginStatus == responseEnum.UpdateProfileRequired.code) {
          sessionStorage.setItem('isUpdateNeeded', '1');
          
          this.router.navigate(['/userprofile']);
        }
        this.loading = false;
        //
      }).catch(err => {
        this.loading = false;
        if(err == errorMessage){
          this.error = err;
        }
        else{
          this.error = errorMessage;
        }
      });
    }

  redirect() {
      if(this.returnUrl == null){
        this.router.navigate(['/userprofile']);
      }
      else {
        sessionStorage.removeItem("returnUrl");
        sessionStorage.removeItem('Client_Id');
        this.cookieService.delete('Client_Id');
        window.location.href = this.returnUrl;
      }
      
    }

    onNewAccount(){
      this.router.navigate(['/register']);
    }

    async onCaptchaResult(e){
      if(e){
        this.isCaptchaNeeded = true;
        await this.sendLoginRequest();
      }
      else{
        //await this.captchaComponent.generateCaptcha();
        this.loading = false;
        this.error = 'القيمة المدخلة غير صحيحة الرجاء إعادة المحاولة';
      }
    }

    forgetPassword(){
      this.router.navigate(['/forgetPassword']);
    }
    goToFAQ() {
    this.router.navigate(['/FAQ']);
    }
    onCaptchaError(e){

      this.error = "errorMessage";
      
    }


}
