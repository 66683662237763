import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-absher-login',
    templateUrl: './absher-login.component.html',
    styleUrls: ['./absher-login.component.scss']
})
/** absher-login component*/
export class AbsherLoginComponent implements OnInit{
    /** absher-login ctor */
  constructor()
  {
  }

  ngOnInit() {
   
  };

}
