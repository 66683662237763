import { Component, OnInit , Output , EventEmitter, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../_services/authentication.service';
import {CaptchaModel, CaptchaQuestion} from '../../../_models/CaptchaModel';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit {
  captchaForm:FormGroup;
  @Input()
  submitted:boolean = false;
  loading:boolean = false;
  @Input()
  captchaQuestion:CaptchaQuestion
  error = 'القيمة المدخلة غير صحيحة الرجاء إعادة المحاولة';
  @Output() captchaResult = new EventEmitter<any>();
  @Output() errorEmitter = new EventEmitter<any>();
  isWrongAnswer: boolean = false;
  
  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    ) {
      this.captchaForm = this.formBuilder.group({
        userValue: ['' , [Validators.required]],
       
        
      });

     }

  async ngOnInit() {
    let resQuestion;
    await this.authenticationService.generateCaptcha().then(res =>{
      resQuestion = res
    });
   
    this.captchaQuestion = resQuestion;
  }

  async generateCaptcha(){
    let resQuestion;
    await this.authenticationService.generateCaptcha().then(res =>{
      resQuestion = res
    });
    this.captchaQuestion = resQuestion;
   }

  async validateCaptcha(){
    let captchaModel : CaptchaModel = {
      key : this.captchaQuestion.encryptedRes,
      userValue: this.f.userValue.value
    }
     
    await this.authenticationService.validateCaptcha(captchaModel).then(result => {
      if(!result){
        this.generateCaptcha();
      }
      this.captchaResult.emit(result);
      
  
    }).catch(err => {
      //ERROR EMIITER//
      this.errorEmitter.emit('error');
    });;
    
  }

  get f() { return this.captchaForm.controls; }


}
